<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="row justify-content-between align-items-center mb-4">
            <div class="col-12 col-md-8 col-lg-9 d-flex">
              <base-input
                type="search"
                v-model="searchedUser"
                @input="searchUser()"
                :placeholder="$t('searchUser')"   
                class="w-100 m-auto"
              >
              </base-input>
              <base-button simple type="primary" class="clean-button ml-sm-2" @click="cleanSearch()">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </div>
          <base-table
            v-if="!isSpinnerShow"
            :data="users"
            :columns="usersColumns"
            :columnsSize="columnsSize"
            class="col table-responsive-sm"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idUser") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col"></th>
            </template>
            <template slot-scope="{ row }">
              <td class="col-1">{{ row.id }}</td>
              <td @click="goToDetailView(row.id)">{{ row.name }}</td>
              <td @click="goToDetailView(row.id)">{{ row.email }}</td>
              <td class="col-2">
                <base-button :disabled="resetButtonsDisabled.includes(row.id)" simple type="primary" class="col" @click="resetPassword(row.id)">
                <Spinner v-if="resetButtonsDisabled.includes(row.id)" classes="mx-auto"></Spinner>
                <span v-else>{{ $t('resetPassword') }}</span>
                </base-button>
              </td>
            </template>
          </base-table>
          <base-table
              v-else-if="users.length === 0"
              :data="[1]"
              class="table-responsive-md"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idUser") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
            </template>
            <template>
              <td class="col-3"></td>
              <td class="col-3"></td>
              <td class="col-3"><Spinner parentClasses="justify-content-start"></Spinner></td>
              <td class="col-3"></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination
              v-if="users.length > 0"
              @turnPage="getItems"
              element="user"
              :perPage="pagination.perPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :isChanging="pagination.isChanging"
              :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, BaseTable, Pagination, Spinner, BaseInput } from "@/components/index";
import { mapState } from "vuex";
import { Popover } from "element-ui";

export default {
  name: "UserList",
  data() {
    return {
      route: "/users",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      users: [],
      usersFiltered: [],
      usersColumns: ["id user", "name", "email", "actions"],
      columnsSize: [null, null, null, "50px"],
      isSpinnerShow: true,
      isPopoverVisible: false,
      currentPage: 1,
      searchedUser: null,
      searchTimer: null,
      resetButtonsDisabled: []
    };
  },
  computed: {
    ...mapState(["isAdmin", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.currentPage = page;
      this.pagination.isChanging = true;
      const route = `${this.route}?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.users;
        this.users = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.users.length === 0;
      }
      this.pagination.isChanging = false;
    },
    searchUser() {
      if(this.searchTimer) {
        clearTimeout( this.searchTimer)
        this.searchTimer = null
      }
      if(this.searchedUser.length > 0){
        this.searchTimer = setTimeout( async()=>{
          this.currentPage = 1;
          
          let response = await this.axios.get(`${this.route}?query=${this.searchedUser}&page=${this.currentPage}`);
          if (response && response.data.status === "success") {
            const data = response.data.data.users;
            this.users = data.data;
            this.pagination.perPage = data.per_page;
            this.pagination.lastPage = data.last_page;
            this.pagination.totalItems = data.total;
            this.isSpinnerShow = false;
          }
        }, 500)
      }
      if(this.searchedUser.length === 0){
        this.searchedUser = null;
        this.getItems()
      }
    },
    async resetPassword(userId) {
      this.resetButtonsDisabled.push(userId); 
      const route = `${this.route}/${userId}/password`;
      const password = {password: '12345678', password_confirmation: '12345678'};

      let response = await this.axios.put(route, password);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
        this.resetButtonsDisabled = this.resetButtonsDisabled.filter( btnId => btnId !== userId );
      }
    },
    cleanSearch() {
      if(this.searchedUser !== null){
        this.searchedUser = null;
        this.getItems();
      }
    },
    goToDetailView(itemId) {
      this.$router.push({ name: "manageUser", params: { id: itemId } });
    },
  },
  created() {
    if (!this.isAdmin) this.$router.push("/");
  },
  mounted() {
    this.getItems();
  },
  metaInfo() {
    return { title: `${this.$tc('user', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseInput,
    BaseTable,
    Pagination,
    Spinner,
    Popover,
  },
};
</script>

<style>
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
</style>
